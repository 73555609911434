import { on } from 'delegated-events';

const POLL_VOTED_IDS = 'poll-voted-ids';

export default function () {
    const appendToStorage = (data) => {
        let existing = localStorage.getItem(POLL_VOTED_IDS);
        if (existing === null) existing = '';
        localStorage.setItem(POLL_VOTED_IDS, `${existing} ${data}`);
    };

    const checkInStorage = (pollId) => {
        const pollVotedIds = localStorage.getItem(POLL_VOTED_IDS);

        if (pollVotedIds === null) {
            return false;
        } else {
            const idsArray = localStorage.getItem(POLL_VOTED_IDS).trim().split(' ');
            return idsArray.includes(`${pollId}`);
        }
    }

    const fetchPoll = async (poll) => {
        const response = await fetch('/polls/fetchrandom', { method: 'POST' });
        const data = await response.json();
        return data;
    };

    const buildPoll = (pollData) => {
        const pollId = pollData.Id;
        const pollQuestion = pollData.Name;
        const pollAnswers = pollData.Items;
        const alreadyVoted = checkInStorage(pollId);

        const pollHtml = `
            <strong>Ankieta</strong>
            <b>${pollQuestion}</b>
            <ul>
                ${alreadyVoted ? 
                    pollAnswers.sort((a, b) => a.PercentageVotes < b.PercentageVotes).map(answer => {
                        return `<li>
                            <span>${answer.Question}</span>
                            <div>${answer.PercentageVotes}%<i style="width: ${answer.PercentageVotes}%;"></i></div>
                        </li>`;
                    }).join('')
                :
                    pollAnswers.map(answer => {
                        return `<li data-poll-id="${pollId}" data-poll-vote-id="${answer.Id}">${answer.Question}</li>`;
                    }).join('')
                }
            </ul>
        `;

        return pollHtml;
    };

    on('click', '[data-poll-vote-id]', function () {
        const pollId = this.dataset.pollId;
        const pollVoteId = this.dataset.pollVoteId;
        const currentPoll = this.closest('[data-poll]');

        appendToStorage(pollId);

        fetch(`/polls/vote?positionId=${pollVoteId}`, { method: 'POST' })
            .then(response => response.json())
            .then(data => currentPoll.innerHTML = buildPoll(data));
    });

    if ('IntersectionObserver' in window) {
        const allPolls = document.querySelectorAll('[data-poll]');

        allPolls.forEach(poll => {
            const observer = new IntersectionObserver(
                function (entries) {
                    for (var i = 0; i < entries.length; i++) {
                        if (entries[i].isIntersecting) {
                            fetchPoll(poll).then(data => poll.innerHTML = buildPoll(data));
                            observer.unobserve(poll);
                        }
                    }
                }
            );

            observer.observe(poll);
        });
    }
}