import Cookies from 'js-cookie';

const MAX_ITEMS = 20;
let wishlistCookie = Cookies.get('wishlist-cookie');
let array = [];

if (wishlistCookie) {
    array = JSON.parse(wishlistCookie);
}

function draw() {
    document.querySelectorAll('[data-wishlist-id]').forEach(wishlistItem => {
        const pid = wishlistItem.getAttribute('data-wishlist-id');
        const foundPid = array.find(arrayItem => Number(arrayItem.id) === Number(pid));

        if (foundPid) {
            wishlistItem.classList.add('existing');
        } else {
            wishlistItem.classList.remove('existing');
        }
    });
}

export default function () {
    const wishlistLink = document.getElementById('wishlist-box');

    if (wishlistLink) {
        wishlistLink.setAttribute('data-wishlist-count', array.length);
    }

    document.querySelectorAll('[data-wishlist-id]').forEach(item => {
        item.addEventListener('click', function () {
            const pid = this.getAttribute('data-wishlist-id');
            const foundPid = array.find(arrayItem => Number(arrayItem.id) === Number(pid));

            if (foundPid) {
                let index;

                array.some(function (elem, i) {
                    return Number(elem.id) === Number(pid) ? (index = i, true) : false;
                });

                array.splice(index, 1);
            } else {
                if (array.length >= MAX_ITEMS) {
                    array.shift();
                }

                array.push({
                    id: pid,
                    date: new Date().getTime()
                });
            }

            array.sort(function (a, b) { return (a.date > b.date) ? 1 : b.date > a.date ? -1 : 0; });
            Cookies.set('wishlist-cookie', JSON.stringify(array), { expires: 30 });

            wishlistLink.setAttribute('data-wishlist-count', array.length);

            draw();
        });
    });

    draw();
}