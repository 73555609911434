import { showLoader, hideLoader } from './utils/utils';

export default function () {
    const handlesSlider = document.getElementById('slider-handles');
    
    if (!handlesSlider) return;

    import('noUiSlider').then(({ default: noUiSlider }) => {
        const settings = {
            start: [handlesSlider.getAttribute('data-price-min'), handlesSlider.getAttribute('data-price-max')],
            range: {
                'min': [Number(handlesSlider.getAttribute('data-price-min'))],
                'max': [Number(handlesSlider.getAttribute('data-price-max'))]
            },
            step: 1
        };

        function createSlider(item, conf) {
            noUiSlider.create(item, conf);
            item.noUiSlider.on('update', function (values, handle) {
                if (handle === 0) {
                    document.getElementById('priceFrom').value = Math.round(values[handle]);
                }

                if (handle === 1) {
                    document.getElementById('priceTo').value = Math.round(values[handle]);
                }
            });
        }

        function updateSlider(item, from, to) {
            item.noUiSlider.destroy();
            settings.range.min = from;
            settings.range.max = to;
            settings.start = [from, to];

            createSlider(item, settings);
        }

        createSlider(handlesSlider, settings);

        const advSearchCategoryDropdown = document.querySelector('.adv-search-category-dropdown');

        if (!advSearchCategoryDropdown) return;

        advSearchCategoryDropdown.addEventListener('change', function () {
            const advSearchProducerDropdown = document.querySelector('.adv-search-producer-dropdown');
            const requestObject = { categoryId: advSearchCategoryDropdown.value };

            showLoader();

            fetch('/api/frontend/AdvancedSearch', {
                method: 'POST',
                credentials: 'same-origin',
                body: JSON.stringify(requestObject),
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                }
            })
                .then(response => response.json())
                .then(data => {
                    const mmin = data.min;
                    const mmax = data.max;

                    updateSlider(handlesSlider, parseInt(mmin), parseInt(mmax));
                    advSearchProducerDropdown.innerHTML = '';

                    const startingOption = document.createElement('option');

                    startingOption.value = '-1';
                    startingOption.textContent = '- producent -';

                    advSearchProducerDropdown.appendChild(startingOption);

                    if (data.producers) {
                        for (let i = 0; i < data.producers.length; i++) {
                            const option = document.createElement('option');

                            option.value = String(data.producers[i].id);
                            option.textContent = data.producers[i].name;
                            advSearchProducerDropdown.appendChild(option);
                        }
                    }

                    hideLoader();
                })
                .catch(error => {
                    console.log(error);
                    hideLoader();
                });
        });
    });
}
