const initObserver = (sentinel, onFunction, offFunction = null) => {
    if ('IntersectionObserver' in window) {
        let observer = null;

        observer = new IntersectionObserver(
            function (entries) {
                for (var i = 0; i < entries.length; i++) {
                    if (entries[i].isIntersecting) {
                        if (offFunction) offFunction();
                    } else {
                        onFunction();
                        if (!offFunction) observer.unobserve(sentinel);
                    }
                }
            }
        );

        observer.observe(sentinel);
    }
}

export default initObserver;