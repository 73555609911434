export default function () {
    const showcases = document.querySelectorAll('[data-showcase]');
    const points = window.showcases;

    if (showcases.length === 0 && (points === undefined || points.length === 0)) return;

    showcases.forEach(showcase => {
        const showcaseId = +showcase.getAttribute('data-showcase');
        let showcasePoints = points.find(point => point.Id === showcaseId).Points;

        if (showcasePoints.length === 0) return;

        const img = showcase.querySelector('img');

        const initShowcasePoints = () => {
            import('tippy.js').then((module) => {

                showcasePoints = showcasePoints;

                const showcaseCanvas = document.createElement('div');
                showcaseCanvas.classList.add('showcase-canvas');

                const tippyInstances = [];

                showcasePoints.forEach(point => {
                    if (point.Url === '' && point.Description === '' && point.Name === '') return;

                    const singlePoint = document.createElement('div');
                    singlePoint.classList.add('showcase-point');
                    singlePoint.style.top = `${point.Top}%`;
                    singlePoint.style.left = `${point.Left}%`;
                    singlePoint.innerHTML = '<span></span>';
                    showcaseCanvas.appendChild(singlePoint);

                    tippyInstances.push(module.default(singlePoint, {
                        allowHTML: true,
                        interactive: point.Url !== '',
                        delay: [0, 200],
                        placement: 'bottom',
                        arrow: false,
                        animation: 'shift-toward',
                        onShow(instance) {
                            module.hideAll({ exclude: instance })
                        },
                        content:
                            `
                        <div class='tooltip-content'>
                            ${point.Name !== '' ?
                                `<span>${point.Name}</span>`
                                : ''}
                            ${point.Description !== '' ?
                                `<p>${point.Description}</p>`
                                : ''}
                            ${point.Url !== '' ?
                                `<a href='${point.Url}'>Zobacz więcej</a>`
                                : ''}
                        </div>
                        `
                    }));
                });

                tippyInstances[0].show();

                showcase.querySelector('figure').insertAdjacentElement('beforeend', showcaseCanvas);
            });
        }

        if (img.complete) {
            initShowcasePoints();
        } else {
            img.addEventListener('load', initShowcasePoints);
        }
    });
}