import { isVisible } from './utils/utils';

export default function () {
    const dropdownPickers = document.querySelectorAll('.dropdown-picker');

    dropdownPickers.forEach(dp => {
        document.addEventListener('click', function (event) {
            const element = dp;

            if (!element.contains(event.target) && isVisible(element)) {
                element.classList.remove('show-dropdown');
            }
        });

        const selectedLabelItem = dp.querySelector('input[type="radio"]:checked + label');
        const selectedSpanText = selectedLabelItem ? selectedLabelItem.textContent : '---';
        const selectedButton = dp.querySelector('.dropdown-button');

        selectedButton.textContent = selectedSpanText;

        selectedButton.addEventListener('click', function () {
            this.parentElement.classList.toggle('show-dropdown');
        });
    });
}