import { on } from 'delegated-events';

import showPopup from './modals';
import initRecaptcha from './recaptcha';

export default function () {
    const availabilityModal = document.querySelector('.availability-modal');
    const availabilityTrigger = document.querySelector('.availability-modal-trigger');

    if (availabilityModal && availabilityTrigger) {
        availabilityTrigger.addEventListener('click', function () {
            showPopup({ header: 'Powiadom o dostępności', body: document.querySelector('.availability-modal.goshop-modal-content').innerHTML, overloadClass: 'availability-modal-wrapper'});
            window.lazyLoadInstance.update();

            const emailInput = document.querySelector('.goshop-modal .availability-modal-inner input[type="text"]');
            function initReCaptchaOnce() {
                initRecaptcha(document.querySelector('.goshop-modal .captcha-wrapper'));
                emailInput.removeEventListener('focus', initReCaptchaOnce);
            }

            if (emailInput) {
                emailInput.addEventListener('focus', initReCaptchaOnce);
            }
        });

        on('click', '#availability-subscribe', function (e) {
            const recaptchaItem = document.querySelector('.goshop-modal .g-recaptcha-response');

            fetch('/api/frontend/availability', {
                method: 'POST',
                credentials: 'same-origin',
                body: JSON.stringify({
                    email: document.querySelector('.goshop-modal .availability-modal-inner input[type="text"]').value,
                    recaptcha : recaptchaItem  ? recaptchaItem.value : '', 
                    optionId: +document.querySelector('[data-optionid]').getAttribute('data-optionid')
                }),
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                }
            })
            .then(response => response.json())
            .then(data => {
                window.currentModalInstance.close();
                showPopup({ body: data.message });
                window.lazyLoadInstance.update();
            })
            .catch(error => {
                console.log(error);
                window.currentModalInstance.close();
            });
        });
    }
}