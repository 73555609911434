import showPopup from './modals';
import initRecaptcha from './recaptcha';
import { showLoader, hideLoader } from './utils/utils';

const sendQuestion = (form) => {
    const fields = [...form.elements].filter(elem => {
        return elem.type !== 'submit'
    }).map(({ name, type, value }) => {
        return {
            name,
            type,
            value
        };
    });

    if (window.currentModalInstance) {
        window.currentModalInstance.close();
    }

    const recaptchaItem = form.querySelector('.g-recaptcha-response');

    fetch('api/frontend/SendQuestionForm', {
        method: 'POST',
        credentials: 'same-origin',
        body: JSON.stringify({
            fields,
            recaptcha: recaptchaItem ? recaptchaItem.value : ''
        }),
        headers: {
            'Content-Type': 'application/json; charset=utf-8'
        }
    })
    .then(response => response.json())
        .then(data => {
            hideLoader();
            console.log(data)
            showPopup({ body: data.message })

            form.querySelectorAll('.field-wrapper').forEach(wrapper => {
                wrapper.classList.remove('valid');
                wrapper.classList.remove('filled');
            });

            if (window.grecaptcha) {
                const grecaptchaInstances = document.querySelectorAll('.g-recaptcha-response');

                grecaptchaInstances.forEach((instance, index) => {
                    if (recaptchaItem === instance) {
                        window.grecaptcha.reset(index);
                    }
                });
            }
        
            form.reset();
    });
}

export default function () {
    const modalTrigger = document.querySelectorAll('[data-modal-form]');

    if (modalTrigger.length > 0) {
        modalTrigger.forEach(trigger => {
            const type = +trigger.getAttribute('data-modal-form');
            const id = trigger.getAttribute('data-id') ? +trigger.getAttribute('data-id') : null;

            trigger.addEventListener('click', e => {
                e.preventDefault();

                showLoader();

                fetch('api/frontend/FetchQuestionFormTemplate', {
                    method: 'POST',
                    credentials: 'same-origin',
                    body: JSON.stringify({
                        type,
                        id
                    }),
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8'
                    }
                })
                .then(response => response.json())
                    .then(data => {
                        hideLoader();

                        showPopup({
                            header: 'Formularz zapytania',
                            body: data.htmlTemplate,
                            overloadClass: 'form-modal'
                        });

                        import('./front-validation').then(module => module.default([document.querySelector('.goshop-modal [data-run-validator]')]));

                        initRecaptcha(document.querySelector('.goshop-modal .captcha-wrapper'));
                });
            });
        });
    }
}

export { sendQuestion };