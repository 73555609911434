export default function () {
    const inputsNarrowDown = document.querySelectorAll('.gs-filtr-container > input');

    inputsNarrowDown.forEach(input => {
        const filterContainer = input.parentElement;
        const allFiltersLabels = filterContainer.querySelectorAll('label');

        input.addEventListener('input', function (e) {
            const value = e.target.value.toLowerCase();

            if (value.length > 1) {
                allFiltersLabels.forEach(filterLabel => {
                    let name = filterLabel.getAttribute('title').toLowerCase();

                    if (!name.includes(value)) {
                        filterLabel.classList.add('hide');
                    } else {
                        filterContainer.classList.remove('no-filters-found');

                        const isBeginning = name.indexOf(value) === 0;

                        if (!isBeginning) {
                            name = name.charAt(0).toUpperCase() + name.slice(1);
                        }

                        filterLabel.innerHTML = name.replace(value, `<mark>${isBeginning ? value.charAt(0).toUpperCase() + value.slice(1) : value}</mark>`);
                        //filterLabel.innerHTML = name.replace(value, `<mark>${value.toUpperCase()}</mark>`);
                        filterLabel.classList.remove('hide');
                    }
                });

                if ([...allFiltersLabels].every(label => label.classList.contains('hide'))) {
                    filterContainer.classList.add('no-filters-found');
                }

            } else {
                allFiltersLabels.forEach(filterLabel => {
                    filterLabel.innerHTML = filterLabel.getAttribute('title');
                    filterLabel.classList.remove('hide');
                    filterContainer.classList.remove('no-filters-found');
                });
            }
        });
    });
}