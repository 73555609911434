import { on } from 'delegated-events';

import { debounce } from './utils/utils';
import { pjaxFormSubmit } from './pjax-code';

export default function () {
    const handlesSlider = document.getElementById('price-filter-handles');

    if (!handlesSlider) return;

    import('noUiSlider').then(({ default: noUiSlider }) => {
        const inputMin = document.getElementById('filter-price1');
        const inputMax = document.getElementById('filter-price2');
        const settings = {
            range: {
                'min': [Number(handlesSlider.getAttribute('data-price-min'))],
                'max': [Number(handlesSlider.getAttribute('data-price-max'))]
            },
            start: [Number(inputMin.getAttribute('data-from')) || Number(handlesSlider.getAttribute('data-price-min')), Number(inputMax.getAttribute('data-to')) || Number(handlesSlider.getAttribute('data-price-max'))],
            step: 1
        };

        noUiSlider.create(handlesSlider, settings);

        handlesSlider.noUiSlider.on('update', function (values, handle) {
            if (handle === 0) {
                inputMin.value = Math.round(values[handle]);
            }

            if (handle === 1) {
                inputMax.value = Math.round(values[handle]);
            }
        });

        handlesSlider.noUiSlider.on('change', debounce(function () {
            pjaxFormSubmit('#filter');
        }, 1000));

        inputMin.addEventListener('input', debounce(function () {
            handlesSlider.noUiSlider.set([inputMin.value, null]);
            pjaxFormSubmit('#filter');
        }, 1000));

        inputMax.addEventListener('input', debounce(function () {
            handlesSlider.noUiSlider.set([null, inputMax.value]);
            pjaxFormSubmit('#filter');
        }, 1000));

        on('click', '#pills-container .single-pill[data-pill-price]', function () {
            if (this.getAttribute('data-pill-price') === 'priceFrom') {
                inputMin.value = settings.range.min;
                handlesSlider.noUiSlider.set([inputMin.value, null]);
                pjaxFormSubmit('#filter');
            }

            if (this.getAttribute('data-pill-price') === 'priceTo') {
                inputMax.value = settings.range.max;
                handlesSlider.noUiSlider.set([null, inputMax.value]);
                pjaxFormSubmit('#filter');
            }
        });
    });
}