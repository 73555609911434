export default function () {

    document.addEventListener('goshop:cart_action', function (e) {

        var eventItem = e.detail;

        if (eventItem.Add || eventItem.Remove) {

            console.debug('Event cart_action received: ', e.detail);
            console.debug(`Action was: ${eventItem.Add ? 'ADD' : 'REMOVED'}`);

            eventItem.EcommerceItem = JSON.parse(eventItem.EcommerceItemJson);
            console.debug(`Item`, eventItem.EcommerceItem);
            if (typeof gtag !== 'undefined') {
                gtag('event', eventItem.Add ? 'add_to_cart' : "remove_from_cart", {
                    value: eventItem.EcommerceItem.Price,
                    items: [eventItem.EcommerceItem]
                });

            }

            if (typeof dataLayer !== 'undefined') {

                dataLayer.push({ ecommerce: null });
                dataLayer.push({
                    event: eventItem.Add ? 'add_to_cart' : "remove_from_cart",
                    ecommerce: {
                        items: [eventItem.EcommerceItem]
                    }
                });

            }
            if (typeof fbq !== 'undefined') {
                fbq('track', 'AddToCart', {
                    content_type: 'product',
                    content_ids: [eventItem.EcommerceItem.item_id]
                });
            }

        }


    });

    document.addEventListener('pjax:complete', function () {
        if (typeof gtag !== 'undefined') {
            gtag('event', 'page_view');
        }
    });
}