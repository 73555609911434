import { Pagination, Navigation } from 'swiper/modules';

export default function () {
    window.addEventListener('load', () => {
        const anySwiper = document.querySelector('.swiper:not(.product-images)');

        if (!anySwiper) return;

        import('swiper').then((module) => {
            const Swiper = module.default;
            const cs = getComputedStyle(document.documentElement);

            //MAIN PAGE PRODUCTS
            const breakpoints = {
                1: {},
                375: {},
                601: {},
                1100: {},
                1280: {}
            };

            const slider1 = new Swiper('.main-page-products-slider.slider1 .swiper', {
                modules: [Navigation],
                preloadImages: false,
                navigation: {
                    nextEl: '.swiper-button-next.slider-button1',
                    prevEl: '.swiper-button-prev.slider-button1'
                },
                loop: false,
                breakpoints: breakpoints,
                slidesPerView: +cs.getPropertyValue('--prodSwiperCols'),
                spaceBetween: +cs.getPropertyValue('--prodGapSlider').replace('px', '')
            });

            slider1.on('breakpoint', function () {
                slider1.params.slidesPerView = +cs.getPropertyValue('--prodSwiperCols');
                slider1.params.spaceBetween = +cs.getPropertyValue('--prodGapSlider').replace('px', '');
                slider1.update();
            });


            const slider2 = new Swiper('.main-page-products-slider.slider2 .swiper', {
                modules: [Navigation],
                preloadImages: false,
                navigation: {
                    nextEl: '.swiper-button-next.slider-button2',
                    prevEl: '.swiper-button-prev.slider-button2'
                },
                loop: false,
                breakpoints: breakpoints,
                slidesPerView: +cs.getPropertyValue('--prodSwiperCols'),
                spaceBetween: +cs.getPropertyValue('--prodGapSlider').replace('px', '')
            });

            slider2.on('breakpoint', function () {
                slider2.params.slidesPerView = +cs.getPropertyValue('--prodSwiperCols');
                slider2.params.spaceBetween = +cs.getPropertyValue('--prodGapSlider').replace('px', '');
                slider2.update();
            });

        });
    }, false);
}