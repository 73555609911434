import { getScript, isVisible } from './utils/utils';

const loadRecaptchaScript = () => getScript('https://www.google.com/recaptcha/api.js?render=explicit&onload=recaptchaLoadCallback', null);

const drawRecaptcha = (captchaWrapper) => {
    window.grecaptcha.render(captchaWrapper, {
        'callback': function (result) {
            const container = captchaWrapper.closest('[data-recaptcha-form]');
            container.querySelectorAll('.confirm-button[disabled]').forEach(button => button.disabled = false);
        }
    });
};

const initRecaptchas = (captchaWrappers) => {
    captchaWrappers.forEach(captchaWrapper => {
        if (isVisible(captchaWrapper)) {
            drawRecaptcha(captchaWrapper);
        }
    });
}

export default function init(target) {
    const captchaWrappers = document.querySelectorAll('[data-recaptcha-form] .captcha-wrapper');
    const anyVisible = [...captchaWrappers].some(wrapper => isVisible(wrapper));

    if (captchaWrappers.length > 0) {
        if (!window.grecaptcha && anyVisible) {
            loadRecaptchaScript();
        }

        if (window.grecaptcha && target) {
            drawRecaptcha(target);
        }

        window.recaptchaLoadCallback = () => {
            if (target) {
                drawRecaptcha(target);
                return;
            }

            initRecaptchas(captchaWrappers);
        }
    }
}