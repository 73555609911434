export default function () {
    const element = document.getElementById('fb-open');

    if (element) {
        element.addEventListener('click', function () {
            this.parentElement.classList.toggle('show');

            const iframeItem = this.parentElement.querySelector('iframe');
            if (iframeItem.getAttribute('src'))
                return;

            const iframeUrl = iframeItem.getAttribute('data-src');
            iframeItem.setAttribute('src', iframeUrl);

        });
    }
}