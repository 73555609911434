import { getQueryVariable } from './utils/utils';

export default function () {
    const filterGroups = document.querySelectorAll('.gs-filtr-container');
    const pillsContainer = document.getElementById('pills-container');
    const priceFiltering = document.querySelector('.price-filtering');

    if (priceFiltering) {
        const min = priceFiltering.querySelector('#price-filter-handles').getAttribute('data-price-min');
        const max = priceFiltering.querySelector('#price-filter-handles').getAttribute('data-price-max');
        const priceFrom = getQueryVariable('priceFrom') || min;
        const priceTo = getQueryVariable('priceTo') || max;
        const priceGroup = document.createElement('div');

        priceGroup.classList.add('pills-group');
        priceGroup.setAttribute('data-pills-group-name', 'Cena');
        let priceGroupInnerHtml = '';

        if (Number(priceFrom) > min) {
            const priceToElement = `<div class="single-pill" data-pill-price="priceFrom"><span>od <b>${priceFrom} zł</b></span></div>`;
            priceGroupInnerHtml += priceToElement;
        }

        if (Number(priceTo) < max) {
            const priceFromElement = `<div class="single-pill" data-pill-price="priceTo"><span>do <b>${priceTo} zł</b></span></div>`;
            priceGroupInnerHtml += priceFromElement;
        }

        priceGroup.innerHTML = priceGroupInnerHtml;

        if (Number(priceFrom) > min || Number(priceTo) < max) {
            pillsContainer.insertBefore(priceGroup, pillsContainer.firstChild);
        }
    }

    if (filterGroups.length === 0) return;

    filterGroups.forEach(filterGroup => {
        const selectedParams = filterGroup.querySelectorAll('input[type="checkbox"]:checked');
        const filterGroupHeadline = filterGroup.firstElementChild.firstElementChild;

        if (filterGroupHeadline) {
            filterGroupHeadline.innerHTML = '';
        }

        filterGroup.firstElementChild.classList.remove('any-selected');

        if (selectedParams.length === 0) return;

        let selectedParamsString = [...selectedParams].map(param => param.nextElementSibling.textContent.trim()).join(', ');

        if (filterGroupHeadline) {
            filterGroupHeadline.innerHTML = selectedParamsString;
        }

        filterGroup.firstElementChild.classList.add('any-selected');

        const pillsGroup = document.createElement('div');

        pillsGroup.classList.add('pills-group');
        pillsGroup.setAttribute('data-pills-group-name', filterGroup.querySelectorAll('div')[0].getAttribute('data-filter-group'));
        let pillsGroupInnerHtml = '';

        selectedParams.forEach(param => {
            const text = param.nextElementSibling.textContent;
            const name = param.getAttribute('id');
            pillsGroupInnerHtml += `<div class="single-pill" data-pill-id="${name}"><span>${text}</span></div>`;

            pillsGroup.innerHTML = pillsGroupInnerHtml;
        });

        pillsContainer.appendChild(pillsGroup);
    });

    if (pillsContainer && pillsContainer.childElementCount > 0 && !pillsContainer.querySelector('button')) {
        const removeButton = document.createElement('span');

        removeButton.classList.add('remove-all-filters');
        removeButton.textContent = 'Usuń wszystkie filtry';
        pillsContainer.appendChild(removeButton);
    }
}