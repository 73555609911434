import * as basicLightbox from 'basiclightbox';
import { on } from 'delegated-events';

on('click', '.goshop-modal-close', function () {
    if (window.currentModalInstance) {
        window.currentModalInstance.close();
    } else {
        document.querySelector('.basicLightbox').click();
    }
});

export default function ({
    header = 'Powiadomienie',
    body,
    overloadClass = '',
    showCallback = null,
    closeCallback = null,
    anotherCloseSelector = null,
    leftLinks = null,
    rightLinks = null
}) {
    const instance = basicLightbox.create(`
        <div class="goshop-modal">
            ${header !== null ? `<header class="goshop-modal-header">${header}</header>` : ''}
            <section class="goshop-modal-body">
                ${body}

                ${leftLinks || rightLinks ? `
                    <footer class="goshop-modal-footer">
                    ${leftLinks ? leftLinks : ''}
                    ${rightLinks ? rightLinks : ''}
                    </footer>
                ` : ''}
            </section>
            <span class="goshop-modal-close"></span>
        </div>
    `, {
        className: `${overloadClass}`,
        onShow: (instance) => {
            if (anotherCloseSelector) {
                instance.element().querySelector(anotherCloseSelector).onclick = instance.close;
            }

            if (showCallback) {
                showCallback();
            }
        },
        onClose: () => {
            window.currentModalInstance = null;

            if (closeCallback) {
                closeCallback();
            }
        }
    });

    window.currentModalInstance = instance;
    instance.show();
}