export default function () {
    const gsTabs = document.querySelectorAll('.gs-tabs');

    gsTabs.forEach((gsTab, gsTabIdx) => {
        const productsListTabs = gsTab.querySelectorAll('.products-list-tabs');

        if (productsListTabs.length <= 1) return;

        // Build nav
        const nav = document.createElement('nav');

        nav.setAttribute('role', 'tablist');
        nav.setAttribute('aria-label', 'Zakładki z produktami');

        productsListTabs.forEach((plt, idx) => {
            plt.setAttribute('role', 'tabpanel');
            plt.setAttribute('aria-labelledby', `tab-${gsTabIdx}-${idx}`);
            plt.setAttribute('id', `panel-${gsTabIdx}-${idx}`);

            const title = plt.firstElementChild.textContent;
            const button = document.createElement('button');

            button.setAttribute('id', `tab-${gsTabIdx}-${idx}`);
            button.setAttribute('aria-controls', `panel-${gsTabIdx}-${idx}`);
            button.setAttribute('role', 'tab');
            button.setAttribute('aria-selected', false);
            button.textContent = title;

            if (idx === 0) {
                button.setAttribute('aria-selected', true);
            }

            nav.appendChild(button);
        });
        gsTab.insertAdjacentElement('afterbegin', nav);

        const buttons = nav.querySelectorAll('button');

        buttons.forEach((button, idx) => {
            button.addEventListener('click', function () {
                buttons.forEach(button => button.setAttribute('aria-selected', false));
                button.setAttribute('aria-selected', true);

                productsListTabs.forEach(plt => plt.setAttribute('hidden', true));
                productsListTabs[idx].removeAttribute('hidden');
            });
        });

        productsListTabs.forEach(plt => plt.setAttribute('hidden', true));
        productsListTabs[0].removeAttribute('hidden');
        gsTab.classList.add('tab-done');
    });
}